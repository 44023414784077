
<template>
  <div class="frameChoose">
    <div class="top">
      <el-button>全部产品</el-button>
<!--      <el-button v-if="types=='exam'" @click="jcxm">检查收费</el-button>-->
    </div>
    <div class="znfa">智能方案描述：---</div>
    <ul class="cpnr" style="font-size: 16px">
      <li>品牌</li>
      <li>产品名</li>
      <li>价格</li>
      <li>库存</li>
      <li @click="drawer=true">筛选<i class="el-icon-arrow-down el-icon--right"></i></li>
    </ul>

    <div class="cp-box" ref="cpnrs">
      <ul  class="cpnr" style="font-size: 16px" v-for="(item, idx) in cpdata" @click="xz(idx)" >
        <li>{{item.ProductDetail.Brand.BrandName}}</li>
        <li style="text-align: left">
          {{item.ProductDetail.Product.ProductName}}
          <span v-if="item.ProductDetail.AllProduct.ProductSortKey == 'lens'">
            <span v-if="item.ProductDetail.AllProduct.DS || item.ProductDetail.AllProduct.DC">(
              <span style="padding-left: 10px;" v-if="item.ProductDetail.AllProduct.DS">DS:{{item.ProductDetail.AllProduct.DS}}</span>
              <span style="padding-left: 10px;" v-if="item.ProductDetail.AllProduct.DC">DC:{{item.ProductDetail.AllProduct.DC}}</span>)
            </span>
          </span>
          <br/>{{item.ProductDetail.Product.ProductSN}}
        </li>
<!--        <li>{{item.ProductDetail.Product.ProductOfficalPrice}}</li>-->
        <li>{{item.ShopProduct.SPShopPrice}}</li>
        <li>{{item.ShopProduct.kc }}</li>
        <li class="last">
          <p :class="types=='exam'?'nyj':''" style="text-align: center;padding-right: .2rem;" @click.stop="getcpxq(item.ProductDetail.Product.ProductId, idx)">详情</p>
          <p v-if="isz" :class="types=='exam'?'nyj':''" @click.stop="add(item.ProductDetail.Product.ProductId, item.ProductDetail.Product.ProductOfficalPrice, item.ShopProduct.SPbNoCountSale, item.ShopProduct.SPAmount, item.ShopProduct.kc)">加入我的选择</p>
        </li>
      </ul>
    </div>


    <el-dialog
        title="详情"
        :visible.sync="showxq"
        :destroy-on-close="true"
        width="80%">
      <div class="xqbox">
        <img v-for="item in xqimgList" :src="item" alt="">
      </div>

     <div style="padding-top: 20px">
       <el-popover
           placement="top"
           trigger="click">
         <div class="ckxq">
           <ul class="leftps">
             <li>品牌：{{xzxqObj.Brand.BrandName}}</li>
             <li>名称：{{xzxqObj.Product.ProductName}}</li>
             <li>描述：{{xzxqObj.Product.ProductDes}}</li>
           </ul>
           <ul class="rightss">
             <li v-for="item in xzxqObj.ListProperty">
               {{item.PropertyGroup.PropertyGroupName}}：{{item.PropertyValue}}
             </li>
           </ul>
         </div>
         <el-button slot="reference">查看详情</el-button>
       </el-popover>
       <el-button @click="add(cpdata[cpidx].ProductDetail.Product.ProductId, cpdata[cpidx].ProductDetail.Product.ProductOfficalPrice)" type="primary" style="margin-left: 20px">加入选择<i class="el-icon-s-goods"></i></el-button>
     </div>
    </el-dialog>


    <el-drawer
        title=""
        :visible.sync="drawer"
        size="60%">
      <div class="sxbox">

        <div class="left">
          <div>
            <p v-for="itemp in Array.from(ppName.values())"  v-bind:key="itemp">{{itemp}}</p>
          </div>

          <div>
            <div v-for="item in xzpplsit">
              <p v-for="items in item[1]">{{sxList[item[0]].ListProperty[items].PropertyValue}}</p>
            </div>
          </div>
        </div>

        <div class="rights">
          <p class="cpnames">
            <span>产品名称：</span>
            <el-input class="cpmsr" v-model="cpmc" placeholder="请输入内容"></el-input>
          </p>
          <p class="cpnames">
            <span>商品代码：</span>
            <el-input class="cpmsr" v-model="spdm" placeholder="请输入内容"></el-input>
          </p>

          <div class="rights-nr">
            <p>品牌</p>
            <ul>
              <li v-for="(item, idx) in ppList" @click="handleCommand(idx)" :class="item.isxz?'xz':''">{{item.BrandName}}</li>
            </ul>
          </div>

          <div class="rights-nr" v-for="(item, idx) in sxList" v-if="sxList.length">
            <p>{{item.PropertyGroup.PropertyGroupName}}</p>
            <ul>
              <li v-for="(items, idxb) in item.ListProperty" :class="items.isxz?'xz':''" @click="getsx(idx, idxb)">{{items.PropertyValue}}</li>
            </ul>
          </div>
        </div>

        <div class="db">
          <el-button @click="cz">重置</el-button>
          <el-button type="primary" @click="tjs">提交</el-button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import {Loading} from "element-ui";

export default {
  name: "frameChoose",
  data() {
    return {
      cpdata: [],
      cpname: '',
      fy: 1,
      maxfy: '',
      ppList: [],
      sxList: [],
      ppxzList: new Map(),
      ppid: '',
      drawer: false,
      showxq: false,
      cpmc: '',
      xqimgList: [],
      xzxqObj: {
        Brand: {},
        Product: {},
        ListProperty: [{
          PropertyGroup: {}
        }]
      },
      types: 'lens',
      cpidx: 0,
      checkList: [],
      xzlist: [],
      ppName: new Map(),
      xzpplsit: [],
      xzppmap: new Map(),
      cspplist: [],
      cssxList: [],
      isz: true,
      isxyc: true,
      spdm: ''
    }
  },

  watch: {
    "$route.query": {
      deep: true,
      handler(n, o) {
        this.init()
      }
    }
  },

  created() {

    this.init()
  },

  mounted() {
    const _this = this
    this.$refs.cpnrs.addEventListener('scroll', function () {
      if (_this.$refs.cpnrs.scrollTop + _this.$refs.cpnrs.offsetHeight > _this.$refs.cpnrs.scrollHeight-10) {
        if (!_this.isxyc) return
        _this.isxyc = false
        _this.getcpList(1)
      }
    })
  },

  methods: {
    init() {
      if (this.types == 'lens') {
        this.cshTime('product_addToCart')
      }else {
        this.cshTime('product_addToCart_frame')
      }

      this.types = this.$route.query.type
      this.cpdata = []
      this.fy = 1
      this.getpp()
      this.getcpList()
      this.getAllcpSx()
      let arrs = this.$store.state.users.user.Company.ListCompanyConfig
      for (let i = 0; i < arrs.length; i++) {
        if (arrs[i].BCKey == 'ShowSelfProductInZTHT' && arrs[i].BCValue == 1) {
          this.isz = false
          break
        }
      }
    },
    getcpList() {
      if (this.maxfy) {
        if (this.fy == this.maxfy) return this.$message('没有更多了');
      }else {
        this.fy = 1
      }
      this.drawer = false
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      let jk = this.types == 'lens'?'Recommended Lens':'Recommended Frame'

      let str = '',arr = this.xzpplsit
      for (let i = 0; i < arr.length; i++) {
        str += this.sxList[arr[i][0]].PropertyGroup.PropertyGroupKey+':'
        for (let j = 0; j < arr[i][1].length; j++) {
          str += this.sxList[arr[i][0]].ListProperty[arr[i][1][j]].PropertyKey + ','
        }
        str += ';'
      }

      // console.log(`${jk}:${str}`)
      this._http.post(this._getUrl('priceSortList'), {
        SearchString: `${jk}:${str}`,
        "SearchCondition": {
          "ShopId": this.$store.state.bases.shop.ShopId,//实际ShopId
          "IndexMin": this.fy,
          "IndexMax":this.fy + 20,
          "SortKey": this.types,//镜片：lens；镜框：frame
          // "SortKey": 'exam',//镜片：lens；镜框：frame
          "ProductName": this.cpmc,
          "BrandId": this.ppid,//若有筛选品牌则填入该行，多个BrandId用英文逗号拼接
          ProductSN: this.spdm,
        },
        "ReturnInfo": {
          "Product": {
            "Brand": 1
          },
          "ShopProduct":1
        },
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        }
      })
          .then(res => {
            // console.log(res)
            this.isxyc = true
            loadingInstance.close()
            if (res.GetRelationListResult) {
              this.maxfy =  res.RecordCount
              let arr = []
              for (let i = 0; i < res.GetRelationListResult.length; i++) {
                if (res.GetRelationListResult[i].ShopProduct.SPbNoCountSale == 1) {
                  if(res.GetRelationListResult[i].ShopProduct.SPAmount > 0){
                    res.GetRelationListResult[i].ShopProduct.kc = res.GetRelationListResult[i].ShopProduct.SPAmount
                  } else{
                    res.GetRelationListResult[i].ShopProduct.kc = '无限'
                  }
                }else {
                  if(res.GetRelationListResult[i].ShopProduct.SPAmount > 0){
                    res.GetRelationListResult[i].ShopProduct.kc = res.GetRelationListResult[i].ShopProduct.SPAmount
                  } else{
                    res.GetRelationListResult[i].ShopProduct.kc = '无库存'
                  }
                }
                arr.push(res.GetRelationListResult[i])
              }

              this.cpdata = this.cpdata.concat(arr)
              this.fy > res.RecordCount?this.fy = res.RecordCount:this.fy += 20
            }
          })
    },

    getpp() {
      // if (this.types == 'exam') return
      this._http.post(this._getUrl('frameSort'), {
        "SortKey" : this.types,
        ShopId: this.$store.state.bases.shop.ShopId,
        "token": {
          "TimeSpan":1516645838674,
          "sToken":"9C25283F0362F10BFAFE5B5397285019"
        }
      })
      .then(res => {
        if (res.GetPropertyGroupBrandResult.ListBrand&&res.GetPropertyGroupBrandResult.ListBrand.length) {
          this.ppList = res.GetPropertyGroupBrandResult.ListBrand
          this.cspplist = res.GetPropertyGroupBrandResult.ListBrand
        }
        // if (res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty.length) {
        //   this.sxList = res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty
        // }
      })
    },

    handleCommand(idx) {
      let arr = [...this.ppList]
      arr[idx].isxz?arr[idx].isxz='':arr[idx].isxz=1
      this.ppList = arr
      if (this.ppxzList.get(arr[idx].BrandId)) {
        this.ppxzList.delete(arr[idx].BrandId)
        this.ppName.delete(arr[idx].BrandId)
      }else {
        this.ppxzList.set(arr[idx].BrandId, arr[idx].BrandId)
        this.ppName.set(arr[idx].BrandId, arr[idx].BrandName)
      }
      this.ppid = Array.from(this.ppxzList.values()).join(',')
      // this.xzpplsit = Array.from(this.ppName.values()).join(',')
    },

    sscp() {
      if (this.types == 'exam') return
      this.fy = 1
      this.maxfy = ''
      this.cpdata = []
      this.getcpList()
    },

    getcpxq(id, idx) {
      this.cpidx = idx
      this.$router.push({path: '/Iframe', query: {id: id, type: 14}})

      /*this._http.post(this._getUrl('frameDetail'), {
        "SearchCondition": {
          "ProductId": id
        },
        "ReturnInfo": {
          "Brand":true,
          "Sort":true,
          "ListUploadFile":true,
          "ListProperty":true
        },
        "token": {
          "sToken": "E92F7B74AE4E7F08F8C7E94800CB5469",
          "TimeSpan": "1576463918"
        }
      })
      .then(res => {
        let arr = [],obj = res.GetFullResult.ListUploadFile
        if (res.GetFullResult.ListUploadFile && res.GetFullResult.ListUploadFile.length) {
          for (let i = 0; i < res.GetFullResult.ListUploadFile.length; i++) {
            arr.push(obj[i].FullURL)
          }
          this.xqimgList = arr
          this.showxq = true
          this.xzxqObj = JSON.parse(JSON.stringify(res.GetFullResult))
        }
      })*/
    },

    getimgurl(id) {
      // getProductDetailList
      this._http.post(this._getUrl('frameDetail'), {
      })
    },

    add(id, pic, xkc, kc, zkc) {
      // if (this.types == 'exam') return
      // if (this.types == 'lens') {
      //   if ( this.$store.state.Time.timeObj.product_addToCart.ENTER != 0) {
      //     let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.product_addToCart.ENTER)/1000 + Number(this.$store.state.Time.timeObj.product_addToCart.EXIT)
      //     this._api.publicApi.addTime('product_addToCart,product_detail', times)
      //     this.jsTime('product_addToCart', 1)
      //   }
      // }else {
      //   if ( this.$store.state.Time.timeObj.product_addToCart_frame.ENTER != 0) {
      //     let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.product_addToCart_frame.ENTER)/1000 + Number(this.$store.state.Time.timeObj.product_addToCart_frame.EXIT)
      //     this._api.publicApi.addTime('product_addToCart_frame,product_detail', times)
      //     this.jsTime('product_addToCart_frame', 1)
      //   }
      // }

      if (zkc == '无库存') {
        return this.$alert('该产品无库存，加入失败')
      }


      if (!this.$store.state.physicianVisits.xzConsulting) {
        return this.$alert('顾客未登录，请登录')
            .then(res => {
              this.$router.push({path: '/csLanding'})
            })
      }
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录，请登录')
            .then(res => {
              this.$router.push({path: '/employeesLanding'})
            })
      }


      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });

      this._http.post(this._getUrl('frameDetail_addToCart'), {
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        },
        "o": {
          "CHPChooseId": this.$store.state.physicianVisits.xzConsulting,//实际ChooseId
          "CHPProductId": id,//实际ProductId
          "CHPAmount":1,//最终实际数量，如果之前已加入1件，后来再增加到3件，则此处传3
          "CHPS_OP_ALL_Id": "00000000-0000-0000-0000-000000000000",//绑定的处方的S_OP_ALLId，添加时此处可以先填写全0的Guid，后续再修改，镜片类商品需带有处方的S_OP_ALLId才可以下单
          "CHPLeftRightEyes": "",//左右眼或双眼，取值右眼：“OD”\左眼“OS”\双眼“OU”。添加时此处可以先填写空字符串，后续再修改，镜片类商品需带有左右眼信息可以下单
          "CHPProductSortGroup": this.types,//等同于Product.ProductSortKey
          "CHPPrescriptionData":"",//药品类该项需要填入这个结构的字符串，其它类别填空字符串
          "CHPPrice": pic,//价格，暂不清楚是单价还是总价，取ExpertProduct.EPPrice或ShopProduct.SPPrice或Product.ProductOfficalPrice
          CHPExpertId: this.$store.state.users.employees.ExpertId
        },
      })
      .then(res => {
        loadingInstance.close()
        if (res.InsertResult) {
          this.$alert('加入成功！', '提示')
        }
      })
    },

    xz(idx) {
      if (this.types != 'exam') return false
      if (this.checkList.indexOf(idx) == -1) {
        this.checkList.push(idx)
      }else {
        this.checkList.splice(this.checkList.indexOf(idx), 1)
      }
    },

    jcxm() {
      if (this.checkList == 0) return this.$alert('请选择商品！')
      let str = ''
      for (let i = 0; i < this.checkList.length; i++) {
        str += this.cpdata[this.checkList[i]].ProductDetail.Product.ProductId+','
      }
      this._http.post(this._getUrl('CustomerOrder_DirectInsert'), {
        "token" : {"sToken" : "54890E9E4162681879B02AF7B490628E",
          "TimeSpan" : "1642649562"
        },
        "UserId" : this.$store.state.users.user.UserId,
        "ShopId" : this.$store.state.bases.shop.ShopId,
        "ChooseProductId" : "",
        "CompanyId" : this.$store.state.users.user.Company.CompanyId,
        "CAPId" : "",
        "ChooseId" : this.$store.state.physicianVisits.xzConsulting,
        "ProductId" : str,
        "CustomerId" : this.$store.state.users.CsUser.CustomerId,
        "ExpertId" : this.$store.state.users.employees.ExpertId
      })
      .then(res => {
        // console.log(res)

      })
    },

    getAllcpSx() {
      this._http.post(this._getUrl('CCP_GetPropertyGroupBrand'), {
        "token": {
          "sToken": "301F39D31879DAB4F4416D31B4801C27",
          "TimeSpan": "1630915754"
        },
        "ShopId": this.$store.state.bases.shop.ShopId,
        "SortKey":this.types,//镜框frame，镜片lens
        "bAll":"0",
        "bSearch":true
      })
      .then(res => {
        if (res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty.length) {
           this.sxList = JSON.parse(JSON.stringify(res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty))
          this.cssxList = JSON.parse(JSON.stringify(res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty))
        }
      })
    },

    getsx(idx, idxb) {
      let arr = []
      let obj = JSON.parse(JSON.stringify(this.sxList))
      if (this.xzppmap.get(idx)) {
        if (this.xzppmap.get(idx).get(idxb) != undefined) {
          this.xzppmap.get(idx).delete(idxb)
          obj[idx].ListProperty[idxb].isxz = false
        }else {
          this.xzppmap.get(idx).set(idxb, idxb)
        }
      }else {
        this.xzppmap.set(idx, new Map().set(idxb, idxb))
      }

      let arrmap = Array.from(this.xzppmap.keys())
      for (let i = 0; i < arrmap.length; i++) {
        arr.push([arrmap[i], Array.from(this.xzppmap.get(arrmap[i]).values())])
      }
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i][1].length; j++) {
          obj[arr[i][0]].ListProperty[arr[i][1][j]].isxz = true
        }
      }
      this.xzpplsit = arr
      this.sxList = obj
    },

    tjs() {
      // if (this.types == 'exam') return
      this.fy = 1
      this.maxfy = ''
      this.cpdata = []
      this.getcpList()
    },

    cz() {
      this.ppid = ''
      this.xzpplsit = []
      this.ppName = new Map()
      this.xzppmap = new Map()
      this.ppxzList = new Map()
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  beforeDestroy() {
    if (this.types == 'lens') {
      if (this.$store.state.Time.timeObj.product_addToCart.ENTER != 0) {
        this.jsTime('product_addToCart')
      }
    }else {
      if (this.$store.state.Time.timeObj.product_addToCart_frame.ENTER != 0) {
        this.jsTime('product_addToCart_frame')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .frameChoose {
    width: 100vw;
    height: 84vh;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 1vh;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7vw;
    height: 40px;
  }
  .znfa {
    padding: 10px 7vw;
    text-align: left;
  }
  .cpnr {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding: 10px;
      cursor: pointer;
      position: relative;
    }
    li:nth-child(1) {
      width: calc(20% - 20px);
      p {
        width: 18px;
        height: 18px;
        border: 1px solid #40a7f6;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: #40a7f6;
        border-radius: 50%;
      }
    }
    li:nth-child(2) {width: calc(40% - 30px);}
    li:nth-child(3) {width: calc(20% - 40px);}
    li:nth-child(4) {width: 50px}
    li:nth-child(5) {width: calc(20% - 30px);}
    .last {
      display: flex;
      justify-content: center;
      color: #40a7f6;
    }
  }
  .cp-box {
    width: 100%;
    height: calc(84vh - 140px);
    overflow: hidden;
    overflow-y: auto;
    ul {
      border-bottom: 1px solid #000000;
      &:hover {background: #cdcbcb;}
    }
    .xz {background: #cdcbcb;}
  }

  .sxbox {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    .cpmc {
      display: flex;
      align-items: center;
      justify-content: center;
      .cpmc-sr {
        width: 20vw;
      }
    }
    .left {
      width: 25%;
      p {padding: 10px;}
    }
    .rights {
      width: 75%;
      height: 90%;
      background: #FFFFFF;
      overflow-y: auto;
      margin-top: 20px;
      .rights-nr {
        padding: 10px 2vw;
        p {
          font-size: 16px;
          text-align: left;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 0 25px;
          //border-bottom: 1px solid #5a5959;
          padding-bottom: 20px;
          li {
            width: 30%;
            background: #d2d7de;
            height: 35px;
            line-height: 35px;
            border-radius: 5px;
            cursor: pointer;
            margin-left: 2%;
            margin-top: 10px;
            &:hover {
              width: calc(30% - 2px) !important;
              height: 33px;
              line-height: 33px;
              background: #FFFFFF;
              color: #40a7f6;
              border: 1px solid #40a7f6;
            }
          }

        }
      }
    }
    .xz {
      width: calc(30% - 2px) !important;
      height: 33px;
      line-height: 33px;
      background: #FFFFFF !important;
      color: #40a7f6;
      border: 1px solid #40a7f6;
    }
  }

  .xqbox {
    width: 100%;
    height: 60vh;
    overflow-y: auto;
    img {
      width: 100%;
    }
  }
  .ckxq {
    width: 60vw;
    background: #b6b4b4 !important;
    color: #FFFFFF;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    .leftps {
      width: 25vw;
    }
    .rightss {
      width: 25vw;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        width: 48%;
      }
    }
  }

  .nyj {color: #8f8e8e}

  .db {
    width: 100%;
    position: absolute;
    bottom: 1vh;
    display: flex;
    justify-content: center;
  }

  .cpnames {
    //width: 100%;
    padding: 10px 2vw;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    .cpmsr {
      width: 250px;
    }
  }
</style>
